
import React from 'react';
import Color from 'color';
// import GoogleFont from 'react-google-font-loader';
import { makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';

import DonationCard from './DonationCard'
import GiveDirectlyLogo from '../images/give_directly_logo.svg'
import WHO from '../images/who.svg'

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    justifyContent: 'center',
    margin: '0px'
  },
}));

export default function DonationCards() {
  const gridStyles = useGridStyles();
  return (
    <>
      <Grid classes={gridStyles} container spacing={4}>
        <DonationCard
          href={'https://donate.givedirectly.org/covid-19'}
          image={GiveDirectlyLogo}
        />
        <DonationCard
          href={'https://covid19responsefund.org/'}
          subtitle={'Solidarity Response Fund'}
          image={WHO}
        />
        <DonationCard
          href={'https://secure.feedingamerica.org/site/Donation2?28371.donation=form1&df_id=28371'}
          image={'https://www.feedingamerica.org//themes/custom/ts_feeding_america/images/svgs/logo-2020.svg'}
        />
      </Grid>
    </>
  )
}