import logo from './logo.svg';
import './App.css';

import 'fontsource-roboto';

import Header from './components/Header'
import Donation from './components/Donation'

import DonationCardsCauses from './components/DonationCardsCauses'
import DonationCardsCovid from './components/DonationCardsCovid'
import DonationCardsDevs from './components/DonationCardsDevs'

function App() {
  return (
    <div className="App">
      <Header />
      <div className="App-body">
        <h1>Covid Relief</h1>
        <DonationCardsCovid />
        <h1>Worthy Projects</h1>
        <DonationCardsCauses />
        <h1>Support the Developers</h1>
        <DonationCardsDevs />
      </div>
    </div>
  );
}

export default App;
