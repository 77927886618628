
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Header() {
  const classes = useStyles();
    return (
      //   <header className="App-header">
      //   <a
      //     className="App-link"
      //     href="https://shutupandtakemystimmy.com"
      //     target="_blank"
      //     rel="noopener noreferrer"
      //   >
      //     Shut Up and Take My Stimmy!
      //   </a>
      // </header>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Shut Up and Take My Stimmy!
          </Typography>
        </Toolbar>
      </AppBar>
    )
}

export default Header