
import React from 'react';
import Color from 'color';
// import GoogleFont from 'react-google-font-loader';
import { makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import DonationCard from './DonationCard'
import BitcoinQr from '../images/bitcoin_wallet_qr.png'
import VenmoQr from '../images/venmo_qr_small.png'

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    justifyContent: 'center',
    margin: '0px'
  },
}));

export default function DonationCards() {
  const gridStyles = useGridStyles();
  return (
    <>
      <Grid classes={gridStyles} container spacing={4}>
        <DonationCard
          href={'https://venmo.com/ben-greer-0'}
          image={VenmoQr}
        />
        <DonationCard
          title={'BTC'}
          image={BitcoinQr}
        />
      </Grid>
    </>
  )
}