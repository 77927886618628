
import React from 'react';
import Color from 'color';
// import GoogleFont from 'react-google-font-loader';
import { makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import GiveDirectlyLogo from '../images/give_directly_logo.svg'
import VenmoQr from '../images/venmo_qr_small.png'


const useStyles = makeStyles(() => ({
  actionArea: {
    borderRadius: 16,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
  card: {
    width: 256,
    borderRadius: 16,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: `0 6px 12px 0 white
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
    padding: 10, 
    backgroundColor: 'white',
    borderColor: '#282c34',
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  // content: ({ color }) => {
  //   return {
  //     backgroundColor: color,
  //     padding: '1rem 1.5rem 1.5rem',
  //   };
  // },
  title: {
    // fontFamily: 'Keania One',
    fontSize: '1.5rem',
    // color: '#fff',
    textTransform: 'uppercase',
  },
  subtitle: {
    // fontFamily: 'Montserrat',
    // color: '#fff',
    opacity: 0.87,
    marginTop: '2rem',
    fontWeight: 500,
    fontSize: 14,
  },
}));

const mediaStyles = makeStyles(() => ({
  display: 'block',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '200px'
}))

const CustomCard = ({ classes, image, title, subtitle, href }) => {
  return (
    <CardActionArea className={classes.actionArea} onClick={() => { window.open(href, '_blank')}}>
      <Card className={classes.card}>
        <CardMedia classes={mediaStyles} src={image} component='img'/>
        { ((title && title.length > 0) || (subtitle && subtitle.length > 0)) &&
        <CardContent className={classes.content}>
          {(title && title.length > 0) &&
          <Typography className={classes.title} variant={'h4'}>
            {title}
          </Typography>
          }
          {(subtitle && subtitle.length > 0) &&
          <Typography className={classes.subtitle}>{subtitle}</Typography>
          }
        </CardContent>
        } 
      </Card>
    </CardActionArea>
  );
};

export default function DonationCard({image, title, subtitle, href }) {
  const styles = useStyles()
  return (
    <Grid item>
    <CustomCard 
    classes={styles}
    image={image}
    title={title}
    subtitle={subtitle}
    href={href}
    />
    </Grid>
  )
}