
import React from 'react';
import Color from 'color';
// import GoogleFont from 'react-google-font-loader';
import { makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import DonationCard from './DonationCard'

import AcluLogo from '../images/aclu_logo.svg'

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    justifyContent: 'center',
    margin: '0px'
  },
}));

export default function DonationCards() {
  const gridStyles = useGridStyles();
  return (
    <>
      <Grid classes={gridStyles} container spacing={4}>
        <DonationCard
          href={'https://www.npr.org/donations/support'}
          image={'https://media.npr.org/chrome_svg/npr-logo.svg'}
        />
        <DonationCard
          href={'https://signal.org/donate/'}
          image={'https://signal.org/assets/header/logo-f7ef605fe417d5520d38d546b3b774b4261c75220b9904da4d8b2ffc19a761ff.png'}
        />
        <DonationCard
          href={'https://action.aclu.org/give/now'}
          image={AcluLogo}
        />
      </Grid>
    </>
  )
}